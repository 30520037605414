<template>
  <video :src="videoUrl" controls></video>
</template>

<script>
export default {
  data() {
    return {
      videoUrl: '/ADW2RhjuO2/CLN096dev1/s.m3u8'
    }
  }
}
</script>
